import { Pipe, PipeTransform } from '@angular/core';
import { ConfigServiceService } from 'src/app/services/config-service.service';

@Pipe({
  name: 'character'
})
export class CharacterPipe implements PipeTransform {

  categoryMenuDisplayType: any = 3;


  constructor(
    private configService: ConfigServiceService
  ) {
    this.getConfigData();
  }

  transform(value: string, caseType: number): string {
    switch(caseType) {
      case 1:
        return value.toUpperCase();
      case 2:
        return value.toLowerCase();
      case 3:
        return value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
      default:
        return value;
    }
  }

  getConfigData() {
    this.configService.getConfigData().subscribe(
      data => {
        this.categoryMenuDisplayType = data['categoryMenuDisplayType'];
      
      }, 
      error => {
      }
    );
  }

}
